body {
    font-family: "Roboto";
  }


h1,h2,h3,h4,h5,h6{
    font-family: "Anton";
}

.typed-element{
    display: inline-flex !important;
}

.cr{
    color: white;
    text-align: center;
    padding: 20px;
}

  
*, *::before, *::after {
	box-sizing: border-box;
}

#app {
	margin: 0 auto;
	line-height: 1.4;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    font-family: "Roboto";
}

h1 {
	text-align: center;
}


.selectable{
    cursor:pointer !important ;
     -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


html{
background: #1e1e1e;
}

body{
background: #1e1e1e;
}

#app{
background: #1e1e1e;
}